/*****************constants******************/
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500&display=swap");
* {
  padding: 0;
  margin: 0;
  line-height: 40px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
  font-family: "Cairo", sans-serif;
}

body {
  background-color: #ffffff;
  overflow-x: hidden;
  direction: rtl;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

input:focus {
  outline: none;
  zoom: 1;
}

.leaflet-control {
  border-radius: 0;
  border: none;
  box-shadow: none;
}
.leaflet-control .leaflet-control-zoom-in span,
.leaflet-control .leaflet-control-zoom-out span {
  color: #a36709;
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  border: none !important;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #a36709;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #fefcfe;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
.loader img {
  max-width: 70%;
}

/********************************************/
body.en {
  direction: ltr;
}
body.en .ask_service {
  padding-left: 0;
  padding-right: 120px;
}
body.en .about .wrapper img {
  transform: rotateY(180deg);
}
body.en .about .description {
  padding-right: 0;
  padding-left: 120px;
}
body.en .about .description p {
  line-height: 45px;
}
body.en .news form input {
  border-radius: 15px 0 0 15px;
}
body.en .news form button {
  border-radius: 0 15px 15px 0;
}
body.en .news form button img {
  transform: rotateY(180deg);
}
body.en nav .search-lang input {
  left: -130%;
}
body.en .sendmessage .submit_area {
  direction: rtl;
}
body.en .services_header .path_desc span,
body.en .contact_header .path_desc span,
body.en .clients_header .path_desc span,
body.en .know_header .path_desc span,
body.en .employ_header .path_desc span {
  margin-left: 0;
  margin-right: 8px;
}
body.en footer .footer_grid .col {
  padding: 0 20px;
}
body.en footer .footer_grid p {
  font-size: 16px;
  line-height: 24px;
}
body.en footer .footer_grid .services {
  padding: 0 70px;
}
body.en header .swiper .swiper-slide .decription p {
  direction: ltr;
}

nav {
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  z-index: 1000;
  position: fixed;
  padding: 10px 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
}
nav.active {
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
nav.active .nav-items .nav-item .nav-link,
nav.active .search-lang button {
  color: #4c4c4c;
}
nav.active .search-lang span {
  background-color: #4c4c4c;
}
nav.active .search-lang input {
  border-color: #a36709;
  color: #4c4c4c;
}
nav.active .search-lang input::-moz-placeholder {
  color: #4c4c4c;
}
nav.active .search-lang input::placeholder {
  color: #4c4c4c;
}
nav.active .bars_btn i {
  color: #4c4c4c;
}
nav .logo {
  height: 100%;
  transition: none;
}
nav .logo a {
  display: block;
  width: 100%;
  height: 100%;
}
nav .logo a img {
  max-height: 100%;
}
nav .nav-items {
  display: flex;
  gap: 35px;
  position: relative;
}
nav .nav-items .nav-item .nav-link {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  transition: 0.3s ease-in-out;
}
nav .nav-items .nav-item .nav-link.active, nav .nav-items .nav-item .nav-link:hover {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
nav .nav-items .nav-item .nav-link.icon {
  display: flex;
  align-items: center;
  gap: 5px;
}
nav .nav-items .drobdown {
  position: absolute;
  top: 50px;
  border-radius: 8px;
  width: 200px;
  height: 0;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  padding: 0 10px;
  background: linear-gradient(211.13deg, #f1b048 0%, #a36709 100%);
}
nav .nav-items .drobdown ul {
  display: flex;
  flex-direction: column;
}
nav .nav-items .drobdown ul li {
  height: 32.5px;
}
nav .nav-items .drobdown ul li a {
  font-size: 14px;
  display: block;
  height: 100%;
  color: #ffffff;
}
nav .nav-items .drobdown ul li a:hover {
  background: none !important;
  background-clip: initial;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
  color: #4c4c4c;
}
nav .search-lang {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-end;
  gap: 8px;
  position: relative;
}
nav .search-lang .search_box {
  top: 75px;
  left: 82px;
  width: 200px;
  height: 100px;
  background: #ffffff;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
}
nav .search-lang .search_box p {
  color: #a36709;
}
nav .search-lang input {
  height: 24px;
  width: 0;
  transition: all 0.4s ease-in-out;
  border: none;
  background: none;
  outline: none;
  border-bottom: 2px solid #ffffff;
  position: absolute;
  left: 82%;
  top: 4px;
  color: white;
  z-index: -1;
}
nav .search-lang input::-moz-placeholder {
  color: #ffffff;
}
nav .search-lang input::placeholder {
  color: #ffffff;
}
nav .search-lang button {
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  width: 35px;
}
nav .search-lang span {
  display: block;
  height: 20px;
  width: 1px;
  background-color: #ffffff;
}
nav .bars_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  height: 90px;
}
nav .bars_btn i {
  font-size: 17px;
  cursor: pointer;
  color: #ffffff;
}

/******home-start*******/
header {
  width: 100%;
  height: 100vh;
}
header .swiper {
  width: 100%;
  height: 100%;
  direction: ltr;
}
header .swiper .swiper-slide {
  width: 100%;
}
header .swiper .swiper-slide .layer {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
header .swiper .swiper-slide img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
header .swiper .swiper-slide .decription {
  width: 360px;
  height: 100px;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-right: 5px solid #a36709;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
header .swiper .swiper-slide .decription p {
  line-height: 30px;
  direction: rtl;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
}
header .swiper .swiper-slide .right {
  right: 0;
  bottom: 0;
  width: 48%;
  position: absolute;
  background-size: cover;
  height: calc(100% - 100px);
  background-image: url("assets/right_circles.png");
}
header .swiper .swiper-slide .left {
  top: 0;
  left: 0;
  width: 48%;
  position: absolute;
  background-size: cover;
  height: calc(100% - 100px);
  background-image: url("assets/left_circles.png");
}
header .swiper .swiper-pagination-bullets {
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
header .swiper .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 50px !important;
  height: 4px !important;
  border-radius: 2px !important;
  background-color: #ffffff;
}
header .swiper .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ffffff;
}

.about {
  margin-top: 60px;
  width: 100%;
}
.about .about-flex {
  display: flex;
}
.about .description {
  width: 50%;
  padding-right: 120px;
}
.about .description span {
  color: #4c4c4c;
  font-size: 24px;
  line-height: 44px;
}
.about .description h1 {
  margin: 15px 0 30px 0;
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 30px;
}
.about .description p {
  font-size: 24px;
  color: #000000;
  line-height: 55px;
}
.about .wrapper {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.about .wrapper img {
  max-width: 100%;
}
.about .about-grid {
  width: 100%;
  padding: 40px 120px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
}
.about .about-grid .about-card {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  padding: 50px 15px 15px 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.about .about-grid .about-card .card-icon {
  width: 70px;
  height: 70px;
  background: linear-gradient(261.15deg, #f1b048 29.34%, #a36709 1023.47%);
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about .about-grid .about-card .card-icon img {
  width: 45px;
}
.about .about-grid .about-card h2 {
  text-align: center;
  color: #4c4c4c;
}
.about .about-grid .about-card p {
  color: #000000;
  line-height: 26px;
  text-align: center;
  color: #c4c4c4;
}
.about .about-grid .about-card .cen {
  margin-top: 15px;
}
.about .about-grid .about-card .cen-p {
  margin-top: 40px;
}
.about .about-grid .about-card .cen-p li {
  text-align: center;
  color: #c4c4c4;
  line-height: 30px;
}

.services {
  padding: 20px 120px;
}
.services h2 {
  text-align: center;
  color: #4c4c4c;
  margin-bottom: 40px;
}
.services .services_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
  margin-bottom: 80px;
}
.services .services_grid .service_card {
  height: 280px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  background: linear-gradient(268.08deg, #f1b048 0%, #a36709 100%);
}
.services .services_grid .service_card .img {
  width: 100%;
  height: 240px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.services .services_grid .service_card .img img {
  transition: 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.services .services_grid .service_card .img .img_layer {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  opacity: 0;
  transition: all 0.8s ease-in-out;
}
.services .services_grid .service_card .img .img_layer p {
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  line-height: 35px;
}
.services .services_grid .service_card pre {
  text-align: center;
  color: #ffffff;
  font-size: 18px;
}
.services .services_grid .service_card:hover .img img {
  transform: scale(1.1);
}
.services .services_grid .service_card:hover .img .img_layer {
  opacity: 1;
}

.ask_service {
  width: 100%;
  height: 480px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url("assets/ask-service.jpg");
  background-size: cover;
  background-position: 50% 80%;
  display: flex;
  gap: 90px;
  padding-left: 120px;
}
.ask_service h3 {
  color: #ffffff;
  font-size: 24px;
  line-height: 45px;
  font-weight: 400;
  margin-bottom: 20px;
}
.ask_service .ask_descrip {
  width: calc(50% - 20px);
  height: 100%;
}
.ask_service .ask_descrip .map_layer {
  height: inherit;
}
.ask_service .ask_form {
  width: calc(50% - 70px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 0;
}
.ask_service .ask_form form input,
.ask_service .ask_form form textarea {
  background-color: #ffffff !important;
}

.clients {
  width: 100%;
  padding: 40px 120px;
}
.clients h3 {
  text-align: center;
  font-size: 24px;
  color: #c4c4c4;
  font-weight: 400;
  margin-bottom: 40px;
}
.clients .mySwiper {
  width: 100%;
  height: 240px;
  padding: 20px 80px;
  direction: ltr;
}
.clients .mySwiper .swiper_card {
  width: 180px;
  height: 180px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients .mySwiper .swiper_card img {
  padding: 10px;
  width: 100%;
  height: 80%;
  -o-object-fit: contain;
     object-fit: contain;
}
.clients .mySwiper .swiper-button-next::after,
.clients .mySwiper .swiper-button-prev::after {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/*******services-start***/
.services_header {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.services_header .image {
  width: 100%;
  height: 280px;
  background-size: cover;
  background-image: url("assets/service11.jpg");
  background-position: 50% 22%;
}
.services_header .path_desc {
  width: 100%;
  padding: 20px 120px;
  display: flex;
  align-items: center;
}
.services_header .path_desc h2 {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 16px;
  display: flex;
}
.services_header .path_desc h2 p {
  font-size: inherit;
}
.services_header .path_desc span {
  margin-left: 8px;
  font-size: 20px;
}
.services_header .path_desc p {
  display: inline-block;
}

.services_container {
  width: 100%;
  padding: 75px 120px;
  display: flex;
  gap: 80px;
}
.services_container main {
  width: 65%;
}
.services_container aside {
  width: 35%;
  height: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 14px 24px;
  border-radius: 10px;
}
.services_container aside a {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}
.services_container aside a.active, .services_container aside a:hover {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/*******clients-start****/
.clients_header {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.clients_header .image {
  width: 100%;
  height: 280px;
  background-size: cover;
  background-image: url("assets/clients.jpg");
  background-position: 50% 55%;
}
.clients_header .path_desc {
  width: 100%;
  padding: 20px 120px;
  display: flex;
  align-items: center;
}
.clients_header .path_desc h2 {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 16px;
}
.clients_header .path_desc span {
  margin-left: 8px;
  font-size: 20px;
}
.clients_header .path_desc p {
  display: inline-block;
}

.clients_gird {
  width: 100%;
  padding: 80px 120px;
  display: grid;
  grid-template-columns: repeat(4, 220px);
  gap: 81px;
  position: relative;
}
.clients_gird .client_card {
  height: 220px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients_gird .client_card img {
  transition: all 0.5s ease-in-out;
  filter: grayscale(1);
  padding: 10px;
  width: 100%;
  height: 80%;
  -o-object-fit: contain;
     object-fit: contain;
}
.clients_gird .client_card:hover img {
  filter: grayscale(0);
}
.clients_gird button {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  border: none;
  background: none;
  outline: none;
  width: 50px;
  height: 50px;
}
.clients_gird button i {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 30px;
  cursor: pointer;
}

/*******employ-start****/
.employ_header {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 60px;
}
.employ_header .image {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-image: url("assets/employ.jpg");
  background-position: 50% 25%;
}
.employ_header .path_desc {
  width: 100%;
  padding: 20px 120px;
  display: flex;
  align-items: center;
}
.employ_header .path_desc h2 {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 16px;
}
.employ_header .path_desc span {
  margin-left: 8px;
  font-size: 20px;
}
.employ_header .path_desc p {
  display: inline-block;
}

.employ_form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 75px;
}
.employ_form h3 {
  padding-bottom: 30px;
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 24px;
}
.employ_form form {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 50%;
}
.employ_form form .file_feild {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e6e6e6;
  position: relative;
}
.employ_form form .file_feild p {
  font-size: 16px;
  color: #4c4c4c;
}
.employ_form form .file_feild span {
  width: 80px;
  height: 25px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;
  background: none;
  outline: none;
  background-color: #cccccc;
}
.employ_form form input,
.employ_form form textarea {
  height: 50px;
  border-radius: 10px;
  padding: 0 14px;
  border: none;
  background: none;
  outline: none;
  background-color: #e6e6e6;
  font-size: 16px;
  color: #4c4c4c;
}
.employ_form form input::-moz-placeholder, .employ_form form textarea::-moz-placeholder {
  color: #4c4c4c;
}
.employ_form form input::placeholder,
.employ_form form textarea::placeholder {
  color: #4c4c4c;
}
.employ_form form textarea {
  height: 100px;
}
.employ_form form button[type=submit] {
  background: linear-gradient(269.48deg, #f1b048 0%, #a36709 100%);
  border: none;
  outline: none;
  border-radius: 10px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  color: #ffffff;
}
.employ_form form .file_in {
  width: 230px;
  height: 40px;
  cursor: pointer;
  direction: rtl;
}
.employ_form form .file_in::-webkit-file-upload-button {
  cursor: pointer;
  border: none;
  background-color: #c4c4c4;
  padding: 6px;
  color: #000000;
  font-size: 14px;
}

/******knowlege-start**/
.know_header {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.know_header .image {
  width: 100%;
  height: 270px;
  background-size: cover;
  background-image: url("assets/news.png");
  background-position: 50% 60%;
}
.know_header .path_desc {
  width: 100%;
  padding: 20px 120px;
  display: flex;
  align-items: center;
}
.know_header .path_desc h2 {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 16px;
}
.know_header .path_desc span {
  margin-left: 8px;
  font-size: 20px;
}
.know_header .path_desc p {
  display: inline-block;
}

.inner_holder {
  padding: 80px 120px;
}
.inner_holder .blogs_grid {
  display: grid;
  gap: 80px;
  grid-template-columns: repeat(3, 1fr);
}
.inner_holder .blogs_grid .blog {
  height: 300px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.inner_holder .blogs_grid .blog img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.inner_holder .blogs_grid .blog .laer {
  width: 100%;
  height: 120px;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 0 20px;
}
.inner_holder .blogs_grid .blog .laer h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: #ffffff;
  padding-top: 10px;
}
.inner_holder .blogs_grid .blog .laer a {
  color: #ffffff;
  font-size: 10px;
  display: block;
  line-height: 30px;
}
.inner_holder .blogs_grid .blog .laer .line {
  width: 100%;
  height: 1px;
  background-color: #4c4c4c;
  display: block;
}
.inner_holder .blogs_grid .blog .laer .date {
  font-size: 10px;
  color: #ffffff;
  display: block;
  line-height: 25px;
}

.blog_holder {
  width: 100%;
  padding: 80px 120px;
  display: flex;
  gap: 8%;
}
.blog_holder .article_img {
  width: 47%;
}
.blog_holder .article_img img {
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.blog_holder .article_description {
  width: 45%;
}
.blog_holder .article_description h3 {
  color: #2c2c2c;
  line-height: 45px;
  font-size: 24px;
}
.blog_holder .article_description article {
  color: #969696;
  margin-top: 20px;
}

/******Contact-start****/
.contact_header {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.contact_header .image {
  width: 100%;
  height: 270px;
  background-size: cover;
  background-image: url("assets/contact.jpg");
  background-position: 50% 35%;
}
.contact_header .path_desc {
  width: 100%;
  padding: 20px 120px;
  display: flex;
  align-items: center;
}
.contact_header .path_desc h2 {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 16px;
}
.contact_header .path_desc span {
  margin-left: 8px;
  font-size: 20px;
}
.contact_header .path_desc p {
  display: inline-block;
}

.map {
  width: 100%;
  display: flex;
}
.map .form {
  width: 50%;
  padding: 50px 120px;
  background-color: #e6e6e6;
}
.map .map_layer {
  width: 50%;
  height: inherit;
}

.info {
  gap: 220px;
  width: 100%;
  height: 180px;
  display: grid;
  padding: 0 120px;
  margin-bottom: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.info .info_card {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 40px;
  border-bottom: 2px solid transparent;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.info .info_card:hover {
  border-color: #a36709;
}
.info .info_card h4 {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 20px;
}
.info .info_card a {
  line-height: 28px;
  display: block;
  color: #4c4c4c;
  direction: ltr;
  transition: 0.3s ease-in-out;
  font-weight: 500;
}
.info .info_card a:hover {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.info .info_card p {
  line-height: 30px;
  color: #4c4c4c;
  text-align: center;
  width: 200px;
}

/* *****footer-start*** */
footer {
  width: 100%;
  height: 100%;
  background-color: #000000;
  padding: 40px 100px;
}
footer .footer_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
footer .footer_grid .logo img {
  max-width: 100%;
}
footer .footer_grid .col {
  padding: 0 40px;
}
footer .footer_grid h3 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
footer .footer_grid p {
  color: #ffffff;
  font-size: 16px;
  line-height: 30px;
}
footer .footer_grid .services {
  padding: 0 100px;
}
footer .footer_grid .services ul {
  display: flex;
  flex-direction: column;
}
footer .footer_grid .services ul li {
  line-height: 30px;
}
footer .footer_grid .services ul li a {
  line-height: 20px;
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  transition: 0.3s ease-in-out;
}
footer .footer_grid .services ul li a:hover {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
footer .line {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #ffffff;
}
footer .contact p {
  width: 200px;
}
footer .contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .contact h4,
footer .contact p,
footer .contact a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
footer .contact a:hover {
  transition: 0.3s ease-in-out;
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* *****news-section*** */
.news {
  width: 100%;
  height: 330px;
  padding: 100px 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  flex-direction: column;
  background-repeat: no-repeat;
  background-image: url("assets/news.png");
}
.news .label {
  width: 800px;
  height: 40px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 46px;
  background: rgba(2, 2, 2, 0.4);
}
.news .label p {
  font-size: 20px;
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.news form {
  width: 100%;
  display: flex;
  gap: 20px;
}
.news form input {
  width: 80%;
  height: 44px;
  border-radius: 0 15px 15px 0;
  border: none;
  background: none;
  outline: none;
  background-color: #ffffff;
  padding: 0 15px;
}
.news form input::-moz-placeholder {
  color: #c4c4c4;
}
.news form input::placeholder {
  color: #c4c4c4;
}
.news form button {
  border: none;
  background: none;
  outline: none;
  height: 44px;
  width: calc(20% - 20px);
  border-radius: 15px 0 0 15px;
  background: linear-gradient(268.08deg, #f1b048 0%, #a36709 100%);
  cursor: pointer;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

/*****send request*******/
.sendmessage {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.sendmessage input {
  height: 35px;
  border-radius: 10px;
  border: none;
  background: none;
  outline: none;
  background-color: #c4c4c4;
  padding: 0 12px;
}
.sendmessage input::-moz-placeholder {
  color: #4c4c4c;
}
.sendmessage input::placeholder {
  color: #4c4c4c;
}
.sendmessage textarea {
  background-color: #c4c4c4;
  height: 100px;
  padding: 0 12px;
  border-radius: 10px;
  outline: none;
  border: none;
}
.sendmessage textarea::-moz-placeholder {
  color: #4c4c4c;
}
.sendmessage textarea::placeholder {
  color: #4c4c4c;
}
.sendmessage .submit_area {
  width: 100%;
  display: flex;
  gap: 5%;
}
.sendmessage .submit_area .submited {
  width: 50%;
  height: 40px;
  pointer-events: none;
  border: 1px solid #a36709;
  border-radius: 10px;
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  display: none;
}
.sendmessage .submit_area button {
  width: 45%;
  height: 40px;
  border: none;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(268.08deg, #f1b048 0%, #a36709 100%);
}

.Toastify {
  position: relative;
  z-index: 10000;
}

@media (max-width: 1200px) {
  body.en .about .description {
    padding-left: 50px;
  }
  body.en .ask_service {
    padding-right: 50px;
  }
  nav {
    padding: 10px 50px;
  }
  nav .search-lang input {
    top: 40px;
  }
  .about .description {
    padding-right: 50px;
  }
  .about .description p {
    font-size: 20px;
    line-height: 45px;
  }
  .clients {
    padding: 40px 50px;
  }
  .about .about-grid {
    padding: 60px 50px;
    gap: 45px;
  }
  .about .about-grid .about-card {
    height: 310px;
  }
  .services {
    padding: 20px 50px;
  }
  .services .services_grid {
    gap: 45px;
  }
  .ask_service {
    padding-left: 50px;
    gap: 40px;
  }
  .ask_service .ask_form {
    width: calc(50% - 20px);
  }
  .news {
    padding: 100px;
  }
  footer {
    padding: 50px;
  }
  footer .footer_grid p {
    font-size: 14px;
    line-height: 24px;
  }
  footer .footer_grid .logo {
    padding: 0;
  }
  footer .footer_grid .col {
    padding: 0 15px;
  }
  footer .footer_grid .services {
    padding: 0 40px;
  }
  .services_container {
    padding: 75px 50px;
  }
  .services_header .path_desc,
  .contact_header .path_desc,
  .employ_header .path_desc,
  .clients_header .path_desc,
  .know_header .path_desc {
    padding: 20px 50px;
  }
  .inner_holder {
    padding: 80px 50px;
  }
  .inner_holder .blogs_grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
  }
  .blog_holder {
    padding: 80px 50px;
  }
  .map .form {
    padding: 50px;
  }
  .info {
    padding: 0 50px;
    gap: 120px;
  }
  .clients_gird {
    padding: 80px 50px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 888px) {
  body.en .ask_service {
    padding: 0;
  }
  body.en .about .description {
    padding: 0 30px;
  }
  body.en .about .description p {
    line-height: 38px;
  }
  body.en nav .search-lang input {
    left: 50%;
    top: -30px;
    transform: translate(-50%, -50%);
  }
  body.en footer .footer_grid .col {
    padding: 0;
  }
  body.en footer .footer_grid .services {
    padding: 0;
  }
  nav {
    padding: 10px 60px;
  }
  nav.opened {
    height: 100vh;
    align-items: flex-start;
    transition: none;
    background: #ffffff;
  }
  nav.opened .logo {
    height: 90px;
  }
  nav.opened .bars_btn i {
    color: #4c4c4c;
  }
  nav.opened .nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    gap: 10px;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
  }
  nav.opened .nav-items i {
    display: none;
  }
  nav.opened .nav-items .drobdown {
    display: none;
  }
  nav.opened .nav-items .nav-item .nav-link {
    color: #4c4c4c;
    text-align: center;
  }
  nav.opened .search-lang {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10%;
    width: 100%;
    left: 0;
  }
  nav.opened .search-lang input {
    left: 50%;
    top: -30px;
    transform: translate(-50%, -50%);
    border-color: #a36709;
    color: #4c4c4c;
  }
  nav.opened .search-lang input::-moz-placeholder {
    color: #4c4c4c;
  }
  nav.opened .search-lang input::placeholder {
    color: #4c4c4c;
  }
  nav.opened .search-lang button {
    color: #4c4c4c;
  }
  nav.opened .search-lang span {
    background-color: #4c4c4c;
  }
  nav .nav-items {
    display: none;
  }
  nav .bars_btn {
    display: flex;
  }
  nav .bars_btn i {
    font-size: 24px;
  }
  nav .search-lang {
    display: none;
  }
  .about {
    margin-top: 0;
  }
  .about .about-flex {
    flex-direction: column-reverse;
  }
  .about .wrapper {
    width: 100%;
    padding-top: 30px;
  }
  .about .description {
    width: 100%;
    padding: 0 40px;
  }
  .about .about-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 100px 40px;
  }
  .about .about-grid .about-card {
    height: 100%;
    padding-bottom: 20px;
  }
  .services {
    padding: 0 40px;
  }
  .services .services_grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .ask_service {
    padding: 0;
    height: 100%;
    flex-direction: column;
    gap: 40px;
  }
  .ask_service .ask_descrip,
  .ask_service .ask_form {
    width: 100%;
    align-items: self-start;
  }
  .ask_service .ask_form {
    padding: 40px 60px;
  }
  .ask_service .ask_descrip {
    height: 600px;
  }
  .clients {
    padding: 40px;
  }
  .news {
    padding: 100px 40px;
  }
  .news .label {
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  .news .label p {
    text-align: center;
  }
  .news form {
    gap: 5%;
  }
  .news form input {
    width: 65%;
  }
  .news form button {
    width: 30%;
  }
  .map {
    flex-direction: column;
  }
  .map .form {
    width: 100%;
    padding: 50px 50px;
  }
  .map .map_layer {
    width: 100%;
    height: 455px;
  }
  .info {
    padding: 0 50px;
    gap: 30px;
  }
  .services_container {
    padding: 70px 50px;
    gap: 50px;
  }
  .employ_header .image,
  .clients_header .image,
  .services_header .image,
  .know_header .image,
  .contact_header .image {
    height: 230px;
  }
  .clients_gird {
    padding: 80px 50px;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
  .blog_holder {
    padding: 50px;
  }
  .inner_holder {
    padding: 80px 50px;
  }
  .inner_holder .blogs_grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
  }
  .employ_form form {
    width: 85%;
  }
  footer {
    padding: 40px 50px;
    height: 100%;
  }
  footer .footer_grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
  footer .footer_grid .col {
    padding: 0;
  }
  footer .line {
    margin: 35px 0;
  }
  footer .contact {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}
@media (max-width: 516px) {
  nav {
    padding: 10px 30px;
  }
  header .swiper .swiper-slide .decription {
    width: 85%;
  }
  header .swiper .swiper-slide .left,
  .right {
    display: none;
  }
  .about .about-grid .about-card {
    height: 100%;
    padding: 50px 20px 35px 20px;
  }
  .about .description {
    width: 100%;
    padding: 0 25px;
    padding-bottom: 40px;
  }
  .about .description h1 {
    line-height: 50px;
    margin: 15px 0;
  }
  .about .description p {
    font-size: 18px;
    line-height: 35px;
  }
  .about .about-grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 70px 25px;
    gap: 70px;
  }
  .services {
    padding: 40px 25px;
  }
  .services .services_grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .ask_service {
    padding: 0;
    gap: 40px;
  }
  .ask_service .ask_descrip,
  .ask_service .ask_form {
    width: 100%;
  }
  .ask_service .ask_form {
    padding: 30px 35px;
  }
  .clients {
    padding: 40px 20px;
  }
  .clients .mySwiper {
    width: 100%;
    padding: 20px 50px;
  }
  .clients .mySwiper .swiper-button-next::after,
  .clients .mySwiper .swiper-button-prev::after {
    font-size: 26px;
    font-weight: 600;
  }
  .news {
    padding: 100px 30px;
  }
  .news .label {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-bottom: 30px;
  }
  .news .label p {
    text-align: center;
    font-size: 18px;
    line-height: 30px;
  }
  .news form {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .news form input {
    width: 100%;
    border-radius: 15px;
  }
  .news form button {
    border-radius: 15px;
    width: 100%;
  }
  .map .form {
    padding: 50px 20px;
  }
  .contact_header .image,
  .know_header .image,
  .employ_header .image,
  .services_header .image,
  .clients_header .image {
    height: 220px;
  }
  .contact_header .path_desc,
  .know_header .path_desc,
  .employ_header .path_desc,
  .services_header .path_desc,
  .clients_header .path_desc {
    padding: 20px;
  }
  .contact_header .path_desc h2,
  .know_header .path_desc h2,
  .employ_header .path_desc h2,
  .services_header .path_desc h2,
  .clients_header .path_desc h2 {
    line-height: 30px;
  }
  .inner_holder {
    padding: 80px 25px;
  }
  .inner_holder .blogs_grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .inner_holder .blogs_grid .blog .laer h4 {
    font-size: 17px;
    line-height: 30px;
  }
  .blog_holder {
    padding: 60px 25px;
    flex-direction: column-reverse;
    gap: 40px;
  }
  .blog_holder .article_img,
  .blog_holder .article_description {
    width: 100%;
  }
  .blog_holder .article_img article,
  .blog_holder .article_description article {
    line-height: 30px;
  }
  .blog_holder .article_img h3,
  .blog_holder .article_description h3 {
    font-size: 21px;
  }
  .clients_gird {
    padding: 60px 40px;
    grid-template-columns: repeat(1, 1fr);
  }
  .employ_form {
    padding: 0 20px;
  }
  .employ_form form {
    width: 100%;
  }
  .employ_form form .file_feild p {
    font-size: 14px;
    line-height: 16px;
  }
  .info {
    height: 100%;
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    padding: 0 80px;
    margin-bottom: 40px;
  }
  .info .info_card {
    padding-bottom: 10px;
  }
  .map .form {
    padding: 50px 30px;
  }
  .services_container {
    flex-direction: column;
    padding: 60px 25px;
    gap: 40px;
  }
  .services_container aside {
    width: 100%;
  }
  .services_container main {
    width: 100%;
  }
  body.en .news form input {
    border-radius: 15px;
  }
  body.en .news form button {
    border-radius: 15px;
  }
}/*# sourceMappingURL=global.css.map */