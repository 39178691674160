/*****************constants******************/
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500&display=swap');
$white: #ffffff;
$black: #000000;
$gold1: #f1b048;
$gold2: #a36709;
$gray0: #e6e6e6;
$gray1: #c4c4c4;
$gray2: #4c4c4c;
$gold_gradient: linear-gradient(261.15deg, #f1b048 29.34%, #a36709 1023.47%);
$gold_gradient2: linear-gradient(268.08deg, #f1b048 0%, #a36709 100%);
$dark_gradient: linear-gradient(180deg, #000000 -40.09%, rgba(0, 0, 0, 0) 100%);
$shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
@mixin centerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin clearInput {
  border: none;
  background: none;
  outline: none;
}
@mixin grad-text {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
* {
  padding: 0;
  margin: 0;
  line-height: 40px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Cairo', sans-serif;
}
body {
  background-color: $white;
  overflow-x: hidden;
  direction: rtl;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
input:focus {
  outline: none;
  zoom: 1;
}
.leaflet-control {
  border-radius: 0;
  border: none;
  box-shadow: none;
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    span {
      color: $gold2;
      display: flex;
      width: 100%;
      height: 100%;
      font-size: 20px;
      align-items: center;
      justify-content: center;
      border: none !important;
    }
  }
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background-color: $white;
}
::-webkit-scrollbar-thumb {
  background: $gold2;
}
.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #fefcfe;
  @include centerFlex();
  img {
    max-width: 70%;
  }
  z-index: 100000;
}
/********************************************/
body.en {
  direction: ltr;
  .ask_service {
    padding-left: 0;
    padding-right: 120px;
  }
  .about {
    .wrapper img {
      transform: rotateY(180deg);
    }
    .description {
      padding-right: 0;
      padding-left: 120px;
      p {
        line-height: 45px;
      }
    }
  }
  .news form {
    input {
      border-radius: 15px 0 0 15px;
    }
    button {
      border-radius: 0 15px 15px 0;
      img {
        transform: rotateY(180deg);
      }
    }
  }
  nav .search-lang input {
    left: -130%;
  }
  .sendmessage .submit_area {
    direction: rtl;
  }
  .services_header,
  .contact_header,
  .clients_header,
  .know_header,
  .employ_header {
    .path_desc span {
      margin-left: 0;
      margin-right: 8px;
    }
  }
  footer .footer_grid {
    .col {
      padding: 0 20px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
    .services {
      padding: 0 70px;
    }
  }
  header .swiper .swiper-slide .decription p {
    direction: ltr;
  }
}
nav {
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  z-index: 1000;
  position: fixed;
  padding: 10px 120px;
  @include space-between();
  transition: all .3s ease-in-out;
  background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
  &.active {
    background: $white;
    box-shadow: $shadow;
    .nav-items .nav-item .nav-link,
    .search-lang button {
      color: $gray2;
    }
    .search-lang span {
      background-color: $gray2;
    }
    .search-lang input {
      border-color: $gold2;
      color: $gray2;
      &::placeholder {
        color: $gray2;
      }
    }
    .bars_btn i {
      color: $gray2;
    }
  }
  .logo {
    height: 100%;
    transition: none;
    a {
      display: block;
      width: 100%;
      height: 100%;
      img {
        max-height: 100%;
      }
    }
  }
  .nav-items {
    display: flex;
    gap: 35px;
    position: relative;
    .nav-item  .nav-link {
      font-size: 18px;
      color: $white;
      font-weight: 500;
      transition: .3s ease-in-out;
      &.active,
      &:hover {
        @include grad-text();
      }
      &.icon {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .drobdown {
      position: absolute;
      top: 50px;
      border-radius: 8px;
      width: 200px;
      height: 0;
      overflow: hidden;
      transition: .5s ease-in-out;
      padding: 0 10px;
      background: linear-gradient(211.13deg, #f1b048 0%, #a36709 100%);
      ul {
        display: flex;
        flex-direction: column;
        li {
          height: calc(260px / 8);
          a {
            font-size: 14px;
            display: block;
            height: 100%;
            color: $white;
            &:hover {
              background: none !important;
              background-clip: initial;
              -webkit-background-clip: initial;
              -webkit-text-fill-color: initial;
              color: $gray2;
            }
          }
        }
      }
    }
  }
  .search-lang {
    width: 100px;
    @include centerFlex();
    justify-content: flex-end;
    gap: 8px;
    position: relative;
    .search_box {
      top: 75px;
      left: 82px;
      width: 200px;
      height: 100px;
      background: $white;
      position: absolute;
      display: none;
      align-items: center;
      justify-content: center;
      p {
        color: $gold2;
      }
    }
    input {
      height: 24px;
      width: 0;
      transition: all .4s ease-in-out;
      @include clearInput();
      border-bottom: 2px solid $white;
      position: absolute;
      left: 82%;
      top: 4px;
      color: white;
      z-index: -1;
      &::placeholder {
        color: $white;
      }
    }
    button {
      @include clearInput();
      font-size: 16px;
      color: $white;
      cursor: pointer;
      width: 35px;
    }
    span {
      display: block;
      height: 20px;
      width: 1px;
      background-color: $white;
    }
  }
  .bars_btn {
    @include centerFlex();
    display: none;
    height: 90px;
    i {
      font-size: 17px;
      cursor: pointer;
      color: $white;
    }
  }
}
/******home-start*******/
header {
  width: 100%;
  height: 100vh;
  .swiper {
    width: 100%;
    height: 100%;
    direction: ltr;
    .swiper-slide {
      width: 100%;
      .layer {
        background: rgba(0, 0, 0, .2);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .decription {
        width: 360px;
        height: 100px;
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        border-right: 5px solid #a36709;
        padding: 0 15px;
        @include centerFlex();
        p {
          line-height: 30px;
          direction: rtl;
          text-align: center;
          font-size: 18px;
          color: $white;
        }
      }
      .right {
        right: 0;
        bottom: 0;
        width: 48%;
        position: absolute;
        background-size: cover;
        height: calc(100% - 100px);
        background-image: url("assets/right_circles.png");
      }
      .left {
        top: 0;
        left: 0;
        width: 48%;
        position: absolute;
        background-size: cover;
        height: calc(100% - 100px);
        background-image: url("assets/left_circles.png");
      }
    }
    .swiper-pagination-bullets {
      height: 40px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .swiper-pagination-bullet {
        width: 50px !important;
        height: 4px !important;
        border-radius: 2px !important;
        background-color: $white;
        &.swiper-pagination-bullet-active {
          background-color: $white;
        }
      }
    }
  }
}
.about {
  margin-top: 60px;
  width: 100%;
  .about-flex {
    display: flex;
  }
  .description {
    width: 50%;
    padding-right: 120px;
    span {
      color: $gray2;
      font-size: 24px;
      line-height: 44px;
    }
    h1 {
      margin: 15px 0 30px 0;
      @include grad-text();
      font-size: 30px;
    }
    p {
      font-size: 24px;
      color: $black;
      line-height: 55px;
    }
  }
  .wrapper {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    img {
      max-width: 100%;
    }
  }
  .about-grid {
    width: 100%;
    padding: 40px 120px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px;
    .about-card {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      padding: 50px 15px 15px 15px;
      box-shadow: $shadow;
      position: relative;
      .card-icon {
        width: 70px;
        height: 70px;
        background: $gold_gradient;
        position: absolute;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 10px;
        @include centerFlex();
        img {
          width: 45px;
        }
      }
      h2 {
        text-align: center;
        color: $gray2;
      }
      p {
        color: $black;
        line-height: 26px;
        text-align: center;
        color: $gray1;
      }
      .cen {
        margin-top: 15px;
      }
      .cen-p {
        margin-top: 40px;
        li {
          text-align: center;
          color: $gray1;
          line-height: 30px;
        }
      }
    }
  }
}
.services {
  padding: 20px 120px;
  h2 {
    text-align: center;
    color: $gray2;
    margin-bottom: 40px;
  }
  .services_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px;
    margin-bottom: 80px;
    .service_card {
      height: 280px;
      border-radius: 20px;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
      background: $gold_gradient2;
      .img {
        width: 100%;
        height: 240px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        img {
          transition: .5s ease-in-out;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .img_layer {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
          @include centerFlex();
          padding: 0 50px;
          opacity: 0;
          transition: all .8s ease-in-out;
          p {
            text-align: center;
            font-size: 16px;
            color: $white;
            line-height: 35px;
          }
        }
      }
      pre {
        text-align: center;
        color: $white;
        font-size: 18px;
      }
      &:hover {
        .img img {
          transform: scale(1.1);
        }
        .img .img_layer {
          opacity: 1;
        }
      }
    }
  }
}
.ask_service {
  width: 100%;
  height: 480px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("assets/ask-service.jpg");
  background-size: cover;
  background-position: 50% 80%;
  display: flex;
  gap: 90px;
  padding-left: 120px;
  h3 {
    color: $white;
    font-size: 24px;
    line-height: 45px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .ask_descrip {
    width: calc(50% - 20px);
    height: 100%;
    .map_layer {
      height: inherit;
    }
  }
  .ask_form {
    width: calc(50% - 70px);
    @include space-between();
    flex-direction: column;
    padding: 30px 0;
    form {
      input,
      textarea {
        background-color: $white !important;
      }
    }
  }
}
.clients {
  width: 100%;
  padding: 40px 120px;
  h3 {
    text-align: center;
    font-size: 24px;
    color: $gray1;
    font-weight: 400;
    margin-bottom: 40px;
  }
  .mySwiper {
    width: 100%;
    height: 240px;
    padding: 20px 80px;
    direction: ltr;
    .swiper_card {
      width: 180px;
      height: 180px;
      box-shadow: $shadow;
      border-radius: 10px;
      @include centerFlex();
      img {
        padding: 10px;
        width: 100%;
        height: 80%;
        object-fit: contain;
      }
    }
    .swiper-button-next::after,
    .swiper-button-prev::after {
      @include grad-text();
    }
  }
}

/*******services-start***/
.services_header {
  width: 100%;
  height: 100%;
  box-shadow: $shadow;
  .image {
    width: 100%;
    height: 280px;
    background-size: cover;
    background-image: url("assets/service11.jpg");
    background-position: 50% 22%;
  }
  .path_desc {
    width: 100%;
    padding: 20px 120px;
    display: flex;
    align-items: center;
    h2 {
      @include grad-text();
      font-size: 16px;
      display: flex;
      p {
        font-size: inherit;
      }
    }
    span {
      margin-left: 8px;
      font-size: 20px;
    }
    p {
      display: inline-block;
    }
  }
}
.services_container {
  width: 100%;
  padding: 75px 120px;
  display: flex;
  gap: 80px;
  main {
    width: 65%;
  }
  aside {
    width: 35%;
    height: 100%;
    box-shadow: $shadow;
    padding: 14px 24px;
    border-radius: 10px;
    a {
      color: $black;
      font-size: 18px;
      font-weight: 500;
      &.active,
      &:hover {
        @include grad-text();
      }
    }
  }
}
/*******clients-start****/
.clients_header {
  width: 100%;
  height: 100%;
  box-shadow: $shadow;
  .image {
    width: 100%;
    height: 280px;
    background-size: cover;
    background-image: url("assets/clients.jpg");
    background-position: 50% 55%;
  }
  .path_desc {
    width: 100%;
    padding: 20px 120px;
    display: flex;
    align-items: center;
    h2 {
      @include grad-text();
      font-size: 16px;
    }
    span {
      margin-left: 8px;
      font-size: 20px;
    }
    p {
      display: inline-block;
    }
  }
}
.clients_gird {
  width: 100%;
  padding: 80px 120px;
  display: grid;
  grid-template-columns: repeat(4, 220px);
  gap: 81px;
  position: relative;
  .client_card {
    height: 220px;
    box-shadow: $shadow;
    border-radius: 10px;
    cursor: pointer;
    @include centerFlex();
    img {
      transition: all .5s ease-in-out;
      filter: grayscale(1);
      padding: 10px;
      width: 100%;
      height: 80%;
      object-fit: contain;
    }
    &:hover {
      img {
        filter: grayscale(0);
      }
    }
  }
  button {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    @include clearInput();
    width: 50px;
    height: 50px;
    i {
      @include grad-text();
      font-size: 30px;
      cursor: pointer;
    }
  }
}

/*******employ-start****/
.employ_header {
  width: 100%;
  height: 100%;
  box-shadow: $shadow;
  margin-bottom: 60px;
  .image {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-image: url("assets/employ.jpg");
    background-position: 50% 25%;
  }
  .path_desc {
    width: 100%;
    padding: 20px 120px;
    display: flex;
    align-items: center;
    h2 {
      @include grad-text();
      font-size: 16px;
    }
    span {
      margin-left: 8px;
      font-size: 20px;
    }
    p {
      display: inline-block;
    }
  }
}
.employ_form {
  width: 100%;
  @include centerFlex();
  flex-direction: column;
  margin-bottom: 75px;
  h3 {
    padding-bottom: 30px;
    @include grad-text();
    font-size: 24px;
  }
  form {
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 50%;
    .file_feild {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      padding: 0 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $gray0;
      position: relative;
      p {
        font-size: 16px;
        color: $gray2;
      }
      span {
        width: 80px;
        height: 25px;
        font-size: 10px;
        @include centerFlex();
        flex-direction: column;
        @include clearInput();
        background-color: #cccccc;
      }
    }
    input,
    textarea {
      height: 50px;
      border-radius: 10px;
      padding: 0 14px;
      @include clearInput();
      background-color: $gray0;
      font-size: 16px;
      color: $gray2;
      &::placeholder {
        color: $gray2;
      }
    }
    textarea {
      height: 100px;
    }
    button[type="submit"] {
      background: linear-gradient(269.48deg, #f1b048 0%, #a36709 100%);
      border: none;
      outline: none;
      border-radius: 10px;
      height: 50px;
      font-size: 20px;
      cursor: pointer;
      color: #ffffff;
    }
    .file_in {
      width: 230px;
      height: 40px;
      cursor: pointer;
      direction: rtl;
      &::-webkit-file-upload-button {
        cursor: pointer;
        border: none;
        background-color: $gray1;
        padding: 6px;
        color: $black;
        font-size: 14px;
      }
    }
  }
}

/******knowlege-start**/
.know_header {
  width: 100%;
  height: 100%;
  box-shadow: $shadow;
  .image {
    width: 100%;
    height: 270px;
    background-size: cover;
    background-image: url("assets/news.png");
    background-position: 50% 60%;
  }
  .path_desc {
    width: 100%;
    padding: 20px 120px;
    display: flex;
    align-items: center;
    h2 {
      @include grad-text();
      font-size: 16px;
    }
    span {
      margin-left: 8px;
      font-size: 20px;
    }
    p {
      display: inline-block;
    }
  }
}
.inner_holder {
  padding: 80px 120px;
  .blogs_grid {
    display: grid;
    gap: 80px;
    grid-template-columns: repeat(3, 1fr);
    .blog {
      height: 300px;
      box-shadow: $shadow;
      border-radius: 15px;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .laer {
        width: 100%;
        height: 120px;
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        padding: 0 20px;
        h4 {
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
          color: $white;
          padding-top: 10px;
        }
        a {
          color: $white;
          font-size: 10px;
          display: block;
          line-height: 30px;
        }
        .line {
          width: 100%;
          height: 1px;
          background-color: $gray2;
          display: block;
        }
        .date {
          font-size: 10px;
          color: $white;
          display: block;
          line-height: 25px;
        }
      }
    }
  }
}
.blog_holder {
  width: 100%;
  padding: 80px 120px;
  display: flex;
  gap: 8%;
  .article_img {
    width: 47%;
    img {
      max-width: 100%;
      border-radius: 15px;
      box-shadow: $shadow;
    }
  }
  .article_description {
    width: 45%;
    h3 {
      color: #2c2c2c;
      line-height: 45px;
      font-size: 24px;
    }
    article {
      color: #969696;
      margin-top: 20px;
    }
  }
}
/******Contact-start****/
.contact_header {
  width: 100%;
  height: 100%;
  box-shadow: $shadow;
  .image {
    width: 100%;
    height: 270px;
    background-size: cover;
    background-image: url("assets/contact.jpg");
    background-position: 50% 35%;
  }
  .path_desc {
    width: 100%;
    padding: 20px 120px;
    display: flex;
    align-items: center;
    h2 {
      @include grad-text();
      font-size: 16px;
    }
    span {
      margin-left: 8px;
      font-size: 20px;
    }
    p {
      display: inline-block;
    }
  }
}
.map {
  width: 100%;
  display: flex;
  .form {
    width: 50%;
    padding: 50px 120px;
    background-color: $gray0;
  }
  .map_layer {
    width: 50%;
    height: inherit;
  }
}
.info {
  gap: 220px;
  width: 100%;
  height: 180px;
  display: grid;
  padding: 0 120px;
  margin-bottom: 20px;
  grid-template-columns: repeat(3, 1fr);
  .info_card {
    @include centerFlex();
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 40px;
    border-bottom: 2px solid transparent;
    transition: all .5s ease-in-out;
    cursor: pointer;
    &:hover {
      border-color: $gold2;
    }
    h4 {
      @include grad-text();
      font-size: 20px;
    }
    a {
      line-height: 28px;
      display: block;
      color: $gray2;
      direction: ltr;
      transition: .3s ease-in-out;
      font-weight: 500;
      &:hover {
        @include grad-text();
      }
    }
    p {
      line-height: 30px;
      color: $gray2;
      text-align: center;
      width: 200px;
    }
  }
}
/* *****footer-start*** */
footer {
  width: 100%;
  height: 100%;
  background-color: $black;
  padding: 40px 100px;
  .footer_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .logo img {
      max-width: 100%;
    }
    .col {
      padding: 0 40px;
    }
    h3 {
      color: $white;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    p {
      color: $white;
      font-size: 16px;
      line-height: 30px;
    }
    .services {
      padding: 0 100px;
      ul {
        display: flex;
        flex-direction: column;
        li {
          line-height: 30px;
          a {
            line-height: 20px;
            font-size: 15px;
            color: $white;
            font-weight: 400;
            transition: .3s ease-in-out;
            &:hover {
              @include grad-text();
            }
          }
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 20px 0;
    background-color: $white;
  }
  .contact p {
    width: 200px;
  }
  .contact {
    @include space-between();
    h4,
    p,
    a {
      color: $white;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    a:hover {
      transition: .3s ease-in-out;
      @include grad-text();
    }
  }
}
/* *****news-section*** */
.news {
  width: 100%;
  height: 330px;
  padding: 100px 170px;
  @include centerFlex();
  background-size: cover;
  flex-direction: column;
  background-repeat: no-repeat;
  background-image: url("assets/news.png");
  .label {
    width: 800px;
    height: 40px;
    border-radius: 15px;
    @include centerFlex();
    margin-bottom: 46px;
    background: rgba(2, 2, 2, 0.4);
    p {
      font-size: 20px;
      @include grad-text();
    }
  }
  form {
    width: 100%;
    display: flex;
    gap: 20px;
    input {
      width: 80%;
      height: 44px;
      border-radius: 0 15px 15px 0;
      @include clearInput();
      background-color: $white;
      padding: 0 15px;
      &::placeholder {
        color: $gray1;
      }
    }
    button {
      @include clearInput();
      height: 44px;
      width: calc(20% - 20px);
      border-radius: 15px 0 0 15px;
      background: $gold_gradient2;
      cursor: pointer;
      color: $white;
      font-size: 18px;
      @include centerFlex();
      gap: 5px;
    }
  }
}
/*****send request*******/
.sendmessage {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  input {
    height: 35px;
    border-radius: 10px;
    @include clearInput();
    background-color: $gray1;
    padding: 0 12px;
    &::placeholder {
      color: $gray2;
    }
  }
  textarea {
    background-color: $gray1;
    height: 100px;
    padding: 0 12px;
    border-radius: 10px;
    &::placeholder {
      color: $gray2;
    }
    outline: none;
    border: none;
  }
  .submit_area {
    width: 100%;
    display: flex;
    gap: 5%;
    .submited {
      width: 50%;
      height: 40px;
      pointer-events: none;
      border: 1px solid $gold2;
      border-radius: 10px;
      @include grad-text();
      @include centerFlex();
      font-weight: 600;
      display: none;
    }
    button {
      width: 45%;
      height: 40px;
      border: none;
      color: $white;
      outline: none;
      cursor: pointer;
      font-size: 16px;
      border-radius: 10px;
      @include centerFlex();
      background: $gold_gradient2;
    }
  }
}
.Toastify {
  position: relative;
  z-index: 10000;
}
@media (max-width: 1200px) {
  body.en {
    .about .description {
      padding-left: 50px;
    }
    .ask_service {
      padding-right: 50px;
    }
  }
  nav {
    padding: 10px 50px;
    .search-lang input {
      top: 40px;
    }
  }
  .about .description {
    padding-right: 50px;
    p {
      font-size: 20px;
      line-height: 45px;
    }
  }
  .clients {
    padding: 40px 50px;
  }
  .about .about-grid {
    padding: 60px 50px;
    gap: 45px;
    .about-card {
      height: 310px;
    }
  }
  .services {
    padding: 20px 50px;
    .services_grid {
      gap: 45px;
    }
  }
  .ask_service {
    padding-left: 50px;
    gap: 40px;
    .ask_form {
      width: calc(50% - 20px);
    }
  }
  .news {
    padding: 100px;
  }
  footer {
    padding: 50px;
    .footer_grid {
      p {
        font-size: 14px;
        line-height: 24px;
      }
      .logo {
        padding: 0;
      }
      .col {
        padding: 0 15px;
      }
      .services {
        padding: 0 40px;
      }
    }
  }
  .services_container {
    padding: 75px 50px;
  }
  .services_header,
  .contact_header,
  .employ_header,
  .clients_header,
  .know_header {
    .path_desc {
      padding: 20px 50px;
    }
  }
  .inner_holder {
    padding: 80px 50px;
    .blogs_grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 100px;
    }
  }
  .blog_holder {
    padding: 80px 50px;
  }
  .map .form {
    padding: 50px;
  }
  .info {
    padding: 0 50px;
    gap: 120px;
  }
  .clients_gird {
    padding: 80px 50px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 888px) {
  body.en .ask_service {
    padding: 0;
  }
  body.en .about .description {
    padding: 0 30px;
    p {
      line-height: 38px;
    }
  }
  body.en nav .search-lang input {
    left: 50%;
    top: -30px;
    transform: translate(-50%, -50%);
  }
  body.en footer .footer_grid {
    .col {
      padding: 0;
    }
    .services {
      padding: 0;
    }
  }
  nav {
    &.opened {
      height: 100vh;
      align-items: flex-start;
      transition: none;
      background: $white;
      .logo {
        height: 90px;
      }
      .bars_btn i {
        color: $gray2;
      }
      .nav-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        gap: 10px;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        i {
          display: none;
        }
        .drobdown {
          display: none;
        }
        .nav-item .nav-link {
          color: $gray2;
          text-align: center;
        }
      }
      .search-lang {
        @include centerFlex();
        position: absolute;
        bottom: 10%;
        width: 100%;
        left: 0;
        input {
          left: 50%;
          top: -30px;
          transform: translate(-50%, -50%);
          border-color: $gold2;
          color: $gray2;
          &::placeholder {
            color: $gray2;
          }
        }
        button {
          color: $gray2;
        }
        span {
          background-color: $gray2;
        }
      }
    }
    padding: 10px 60px;
    .nav-items {
      display: none;
    }
    .bars_btn {
      display: flex;
      i {
        font-size: 24px;
      }
    }
    .search-lang {
      display: none;
    }
  }
  .about {
    margin-top: 0;
    .about-flex {
      flex-direction: column-reverse;
    }
    .wrapper {
      width: 100%;
      padding-top: 30px;
    }
    .description {
      width: 100%;
      padding: 0 40px;
    }
    .about-grid {
      grid-template-columns: repeat(2, 1fr);
      padding: 100px 40px;
      .about-card {
        height: 100%;
        padding-bottom: 20px;
      }
    }
  }
  .services {
    padding: 0 40px;
    .services_grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .ask_service {
    padding: 0;
    height: 100%;
    flex-direction: column;
    gap: 40px;
    .ask_descrip,
    .ask_form {
      width: 100%;
      align-items: self-start;
    }
    .ask_form {
      padding: 40px 60px;
    }
    .ask_descrip {
      height: 600px;
    }
  }
  .clients {
    padding: 40px;
  }
  .news {
    padding: 100px 40px;
    .label {
      width: 100%;
      height: 100%;
      padding: 10px;
      p {
        text-align: center;
      }
    }
    form {
      gap: 5%;
      input {
        width: 65%;
      }
      button {
        width: 30%;
      }
    }
  }
  .map {
    flex-direction: column;
    .form {
      width: 100%;
      padding: 50px 50px;
    }
    .map_layer {
      width: 100%;
      height: 455px;
    }
  }
  .info {
    padding: 0 50px;
    gap: 30px;
  }
  .services_container {
    padding: 70px 50px;
    gap: 50px;
  }
  .employ_header,
  .clients_header,
  .services_header,
  .know_header,
  .contact_header {
    .image {
      height: 230px;
    }
  }
  .clients_gird {
    padding: 80px 50px;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
  .blog_holder {
    padding: 50px;
  }
  .inner_holder {
    padding: 80px 50px;
    .blogs_grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 60px;
    }
  }
  .employ_form form {
    width: 85%;
  }
  footer {
    padding: 40px 50px;
    height: 100%;
    .footer_grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
      .col {
        padding: 0;
      }
    }
    .line {
      margin: 35px 0;
    }
    .contact {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
  }
}
@media (max-width: 516px) {
  nav {
    padding: 10px 30px;
  }
  header .swiper .swiper-slide .decription {
    width: 85%;
  }
  header .swiper .swiper-slide .left,
  .right {
    display: none;
  }
  .about {
    .about-grid .about-card {
      height: 100%;
      padding: 50px 20px 35px 20px;
    }
    .description {
      width: 100%;
      padding: 0 25px;
      padding-bottom: 40px;
      h1 {
        line-height: 50px;
        margin: 15px 0;
      }
      p {
        font-size: 18px;
        line-height: 35px;
      }
    }
    .about-grid {
      grid-template-columns: repeat(1, 1fr);
      padding: 70px 25px;
      gap: 70px;
    }
  }
  .services {
    padding: 40px 25px;
    .services_grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .ask_service {
    padding: 0;
    gap: 40px;
    .ask_descrip,
    .ask_form {
      width: 100%;
    }
    .ask_form {
      padding: 30px 35px;
    }
  }
  .clients {
    padding: 40px 20px;
    .mySwiper {
      width: 100%;
      padding: 20px 50px;
      .swiper-button-next::after,
      .swiper-button-prev::after {
        font-size: 26px;
        font-weight: 600;
      }
    }
  }
  .news {
    padding: 100px 30px;
    .label {
      width: 100%;
      height: 100%;
      padding: 10px;
      margin-bottom: 30px;
      p {
        text-align: center;
        font-size: 18px;
        line-height: 30px;
      }
    }
    form {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      input {
        width: 100%;
        border-radius: 15px;
      }
      button {
        border-radius: 15px;
        width: 100%;
      }
    }
  }
  .map {
    .form {
      padding: 50px 20px;
    }
  }
  .contact_header,
  .know_header,
  .employ_header,
  .services_header,
  .clients_header {
    .image {
      height: 220px;
    }
    .path_desc {
      padding: 20px;
      h2 {
        line-height: 30px;
      }
    }
  }
  .inner_holder {
    padding: 80px 25px;
    .blogs_grid {
      grid-template-columns: repeat(1, 1fr);
      .blog .laer h4 {
        font-size: 17px;
        line-height: 30px;
      }
    }
  }
  .blog_holder {
    padding: 60px 25px;
    flex-direction: column-reverse;
    gap: 40px;
    .article_img,
    .article_description {
      width: 100%;
      article {
        line-height: 30px;
      }
      h3 {
        font-size: 21px;
      }
    }
  }
  .clients_gird {
    padding: 60px 40px;
    grid-template-columns: repeat(1, 1fr);
  }
  .employ_form {
    padding: 0 20px;
    form {
      width: 100%;
      .file_feild p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .info {
    height: 100%;
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    padding: 0 80px;
    margin-bottom: 40px;
    .info_card {
      padding-bottom: 10px;
    }
  }
  .map {
    .form {
      padding: 50px 30px;
    }
  }
  .services_container {
    flex-direction: column;
    padding: 60px 25px;
    gap: 40px;
    aside {
      width: 100%;
    }
    main {
      width: 100%;
    }
  }
  body.en .news form input {
    border-radius: 15px;
  }
  body.en .news form button {
    border-radius: 15px;
  }
}
